<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="client.name"
        label="Nome*"
        placeholder="Digite o nome"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="client.rg"
        label="RG"
        placeholder="xx.xxx.xxx-x"
        v-mask="'##.###.###-#'"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="client.cpf"
        label="CPF"
        placeholder="xxx.xxx.xxx-xx"
        v-mask="'###.###.###-##'"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="client.cnh"
        label="CNH"
        placeholder="N° da CNH"
        v-mask="'########-#'"
        outlined
        rounded
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object
  },
  deactivated() {
    this.$emit('deactivated')
  }
}
</script>